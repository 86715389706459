<template>
  <slot />
</template>

<script lang="ts" setup>
import type { TPageTransitionExpose } from '~/components/transitions/pageTransition/pageTransition.type.js';

defineExpose<TPageTransitionExpose>({
  beforeEnter,
  enter,
  leave,
  afterLeave,
})

function beforeEnter(el: HTMLElement) { }

function enter(el: HTMLElement, callback: () => void) {
  callback()
}

function leave(el: HTMLElement, callback: () => void) {
  callback()
}

function afterLeave(el: HTMLElement) { }
</script>
